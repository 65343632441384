import formatMap from './formatMap'
import dayjs from './dayjs'

export default ({ timestamp, format = 'YYYY-MM-DD', locale = 'zh-CN', offset = false, languageHabit = true }) => {
  if (!timestamp) {
    console.error('timestamp参数必传!')
    return ''
  }
  timestamp = Number(timestamp)
  if (!Number.isInteger(timestamp)) {
    console.error('timestamp必须为整数')
    return ''
  }
  if (offset !== false) {
    offset = Number(offset)
    if (!Number.isInteger(offset)) {
      console.error('offset必须为整数')
      return ''
    }
  }
  const language = locale.split('-')[0]
  let targetFormat = ''
  if (language === 'zh') {
    targetFormat = format
  } else {
    if (languageHabit && formatMap[format] && formatMap[format][language]) {
      targetFormat = formatMap[format][language]
    } else {
      if (languageHabit) {
        console.warn('传入的format不支持按照语言自动进行格式转换')
      }
      targetFormat = format
    }
  }
  if (offset === false) {
    return dayjs(timestamp).format(targetFormat)
  } else {
    const time = dayjs(timestamp)
    let rs
    const manipulated = Math.round(offset / 60) - time.utcOffset()
    if (manipulated >= 0) {
      rs = time.add(manipulated, 'minute')
    } else {
      rs = time.subtract(Math.abs(manipulated), 'minute')
    }
    return rs.format(targetFormat)
  }
}
