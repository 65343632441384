
/**
 * 一个统一的处理货币的方法，后端改造后方法
 * @prop {function} currencyFormat
 * @param {Object} config:amount：金额; symbol：货币符号；position：符号位置；symbolTemplate：符号模板；amountTemplate：金额模板；literal 货币符号与数字的分隔符；
 * @returns {String} 拼装好的字符串
 */
export default ({ amount = 0, symbol, position = 0, symbolTemplate = '', amountTemplate = '', literal = '', minusSign = '', minusSignTemplate = '' }) => {
  if (!symbol) {
    console.error('symbol参数必传!')
    return ''
  }
  let symbolStr = symbol
  let amountStr = amount
  let minusSignStr = minusSign
  if (symbolTemplate) {
    symbolStr = symbolTemplate.replace('%s', symbol)
  }
  if (amountTemplate) {
    amountStr = amountTemplate.replace('%s', amount)
  }
  if (minusSignTemplate) {
    minusSignStr = minusSignTemplate.replace('%s', minusSign)
  }
  return position === 0 ? minusSignStr + symbolStr + literal + amountStr : minusSignStr + amountStr + literal + symbolStr
}
