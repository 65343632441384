
const symbolMap = {
  MXN: 'MX$',
  BRL: 'R$'
}
const supportLocale = ['zh-CN', 'en-US', 'es-MX', 'pt-BR']

/**
 * 一个统一的处理货币的方法，后端改造前方法，只关注墨西哥和巴西情况，不支持日本货币
 * @prop {function} currencyFormatByFront
 * @param {Object} locale 语言标识;currency 国家标识;amount 金额，单位是滴分; literal 货币符号与数字的分隔符，默认空格；
 * @returns {Object} symbol：货币符号；amount：格式化后的金额
 */
export default ({ locale, currency, amount = 0, literal = '' }) => {
  if (!locale || !currency) {
    console.error('locale和currency参数必传!')
    return ''
  }
  if (!symbolMap[currency]) {
    console.error('currency不支持')
    return ''
  }
  if (supportLocale.indexOf(locale) === -1) {
    console.error('locale不支持')
    return ''
  }
  amount = Number(amount)
  if (!Number.isInteger(amount)) {
    console.error('金额应该为滴分单位，并且是整型')
    return ''
  }

  let amountStr = new Intl.NumberFormat(locale, { style: 'decimal' }).format(amount / 100)
  let minusSign = ''
  if (amount < 0) {
    minusSign = '-'
    amountStr = amountStr.substring(1)
  }
  let position = 0 // 0 在前, 1 在后

  return {
    minusSign,
    amount: amountStr,
    symbol: symbolMap[currency],
    position,
    display: minusSign + symbolMap[currency] + literal + amountStr
  }
}
