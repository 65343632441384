export default {
  'HH:mm': {
    en: 'hh:mm A',
    es: 'hh:mm A',
    pt: 'hh:mm A',
    ja: 'HH:mm'
  },
  'MM-DD': {
    en: 'MM-DD',
    es: 'DD-MM',
    pt: 'DD-MM',
    ja: 'MM月DD日'
  },
  'MM月DD日': {
    en: 'MM.DD',
    es: 'DD.MM',
    pt: 'DD.MM',
    ja: 'MM月DD日'
  },
  'MM-DD HH:mm': {
    en: 'MM-DD hh:mm A',
    es: 'DD-MM hh:mm A',
    pt: 'DD-MM hh:mm A',
    ja: 'MM-DD HH:mm'
  },
  'YYYY-MM-DD HH:mm': {
    en: 'MM-DD-YYYY hh:mm A',
    es: 'DD-MM-YYYY hh:mm A',
    pt: 'DD-MM-YYYY hh:mm A',
    ja: 'YYYY-MM-DD HH:mm'
  },
  'YYYY年MM月DD日 HH:mm': {
    en: 'MM-DD-YYYY hh:mm A',
    es: 'DD-MM-YYYY hh:mm A',
    pt: 'DD-MM-YYYY hh:mm A',
    ja: 'YYYY/MM/DD HH:mm'
  },
  'YYYY年MM月DD日': {
    en: 'MM.DD.YYYY',
    es: 'DD.MM.YYYY',
    pt: 'DD.MM.YYYY',
    ja: 'YYYY/MM/DD'
  },
  'YYYY.MM.DD HH:mm': {
    en: 'MM.DD.YYYY hh:mm A',
    es: 'DD.MM.YYYY hh:mm A',
    pt: 'DD.MM.YYYY hh:mm A',
    ja: 'YYYY.MM.DD HH:mm'
  },
  'YYYY.MM.DD HH': {
    en: 'MM.DD.YYYY hh A',
    es: 'DD.MM.YYYY hh A',
    pt: 'DD.MM.YYYY hh A',
    ja: 'YYYY.MM.DD HH'
  },
  'YYYY.MM.DD': {
    en: 'MM.DD.YYYY',
    es: 'DD.MM.YYYY',
    pt: 'DD.MM.YYYY',
    ja: 'YYYY.MM.DD'
  },
  'YYYY.MM.DD HH:mm:ss': {
    en: 'MM.DD.YYYY hh:mm:ss A',
    es: 'DD.MM.YYYY hh:mm:ss A',
    pt: 'DD.MM.YYYY hh:mm:ss A',
    ja: 'YYYY.MM.DD HH:mm:ss'
  },
  'YYYY-MM-DD HH:mm:ss': {
    en: 'MM-DD-YYYY hh:mm:ss A',
    es: 'DD-MM-YYYY hh:mm:ss A',
    pt: 'DD-MM-YYYY hh:mm:ss A',
    ja: 'YYYY-MM-DD HH:mm:ss'
  },
  'YYYY-MM-DD': {
    en: 'MM-DD-YYYY',
    es: 'DD-MM-YYYY',
    pt: 'DD-MM-YYYY',
    ja: 'YYYY-MM-DD'
  },
  'MM月DD日 HH时mm分': {
    en: 'MM-DD hh:mm A',
    es: 'DD-MM hh:mm A',
    pt: 'DD-MM hh:mm A',
    ja: 'MM月DD日 HH:mm'
  }
}
