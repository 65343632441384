import currencyFormatComp from "./currency-format";
import currencyFormat from "./currencyFormat";
import currencyFormatByFront from "./currencyFormatByFront";
import getTimeFormat from "./getTimeFormat";
import {
  checkLocale,
  getLocale,
  getLanguage,
  getRegion,
  getCountryNameByLocale,
  LanguageMap,
  CountryMap,
  LocaleMap,
  LanguagePackMap,
  CountryLanguageMap,
  CountryLocaleMap,
  CountryNameMap,
  getLanguageLong,
  getLanguageShort
} from "./locale";
import dayjs from "./dayjs";

const currencyPlugin = {
  install: (Vue, options) => {
    Vue.component("currency-format", currencyFormatComp);
  }
};

export default {
  currencyFormat,
  currencyFormatByFront,
  getTimeFormat,
  checkLocale,
  getLocale,
  getLanguage,
  getLanguageLong,
  getLanguageShort,
  getRegion,
  getCountryNameByLocale,
  LanguageMap,
  CountryMap,
  LocaleMap,
  LanguagePackMap,
  CountryLanguageMap,
  CountryLocaleMap,
  CountryNameMap,
  currencyPlugin,
  dayjs
};
